import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Column from 'src/components/10-atoms/layout/Column';
import Typography from 'src/components/10-atoms/typography/Typography';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import ULItem from 'src/components/10-atoms/typography/ULItem';
const QPImagesGuidelines = ({}) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(Column, { pv: theme.spacing.standard.xs },
        React.createElement(Typography, { variant: TypographyVariant.CAPTION }, "Question Paper images must include:"),
        React.createElement(ULItem, { variant: TypographyVariant.CAPTION }, "Date of examination"),
        React.createElement(ULItem, { variant: TypographyVariant.CAPTION }, "Exam Level (eg: Class II, ETO)"),
        React.createElement(ULItem, { variant: TypographyVariant.CAPTION }, "Subject name (eg: Motor, General)"),
        React.createElement(ULItem, { variant: TypographyVariant.CAPTION }, "All the questions")));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QPImagesGuidelines;
