import { InMemoryCache, gql } from '@apollo/client';
import { createFragmentRegistry } from '@apollo/client/cache';
const graphQLCache = new InMemoryCache({
    typePolicies: {
        EPQuestion: {
            merge: true,
            fields: {
                data: {
                    merge: true,
                },
                config: {
                    merge: true,
                },
                ancestorsDetails: {
                    merge: true,
                },
                questionPaper: {
                    merge: true,
                },
                duplicates: {
                    merge: false,
                },
            },
        },
        EPSubject: {
            merge: true,
            fields: {
                data: {
                    merge: true,
                },
            },
        },
        CommunityQuestion: {
            fields: {
                data: {
                    merge: true,
                },
            },
        },
        CommunityAnswer: {
            fields: {
                data: {
                    merge: true,
                },
            },
        },
        CommunityReply: {
            fields: {
                data: {
                    merge: true,
                },
            },
        },
        Author: {
            fields: {
                details: {
                    merge: true,
                },
                reputation: {
                    merge: true,
                },
            },
        },
    },
    fragments: createFragmentRegistry(gql `
    fragment TranslationInfoFields on TranslationInfo {
      sourceLang
      targetLang
    }
  `, gql `
    fragment AuthorFields on Author {
      id
      details {
        avatarURL
        firstName
        lastName
        userName
      }
    }
  `),
});
export function addTypePolicies(typePolicies) {
    graphQLCache.policies.addTypePolicies(typePolicies);
}
export default graphQLCache;
