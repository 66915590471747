import React, { useState } from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import HTMLEditor from './Editor';
import styleUtils from 'src/utils/styles';
import useFormStyles from 'src/components/10-atoms/form/styles';
import HTMLRenderer from './Renderer';
import Column from 'src/components/10-atoms/layout/Column';
const HTMLEditorInput = React.forwardRef(({ onFocus, onBlur, disabled = false, initialValue, style, ...htmlEditorProps }, ref) => {
    const [focused, setFocused] = useState(false);
    const [{ theme }] = useTheme(stylesGenerator);
    const formStyles = useFormStyles({
        focused,
        disabled,
    });
    return disabled ? (React.createElement(Column, { style: [formStyles.inputContainer, style], pv: theme.spacing.standard.xs },
        React.createElement(HTMLRenderer, { html: initialValue || '' }))) : (React.createElement(HTMLEditor, { ref: ref, style: [formStyles.inputContainer, styleUtils.ovfHidden, style], ph: 0, gutterBottom: false, initialValue: initialValue, onFocus: () => setFocused(true),
        onBlur: () => setFocused(false), ...htmlEditorProps }));
});
const stylesGenerator = createThemedStyle(({}) => ({}));
export default HTMLEditorInput;
