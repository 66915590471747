import React from 'react';
import useStableCallback from 'src/hooks/useCallbackUtils/useStableCallback';
import { bottomDrawerHelperActions } from 'src/components/20-helper-views/bottom-drawer/useBottomDrawer';
import SentForReviewDrawer from './SentForReviewDrawer';
import ThankContributorDrawer from './ThankContributor';
import DuplicateContributionDrawer from './DuplicateContribution';
import FallbackRejectionDrawer from './FallbackRejection';
export default function useContributionResponseHandler({ sentForReviewContent, thankContributorContent, }) {
    const handle = useStableCallback((response) => {
        return new Promise((resolve, reject) => {
            if (response.sentForReview) {
                bottomDrawerHelperActions.show({
                    duration: 7000,
                    component: (React.createElement(SentForReviewDrawer, { content: sentForReviewContent, onConfirm: bottomDrawerHelperActions.hide })),
                    onClose: () => resolve(null),
                });
                return;
            }
            if (!response.reason) {
                bottomDrawerHelperActions.show({
                    duration: 5000,
                    component: (React.createElement(ThankContributorDrawer, { content: thankContributorContent, onConfirm: bottomDrawerHelperActions.hide })),
                    onClose: () => resolve(null),
                });
                return;
            }
            switch (response.reason.code) {
                case 'DUPLICATE': {
                    bottomDrawerHelperActions.show({
                        duration: 5000,
                        component: (React.createElement(DuplicateContributionDrawer, { content: { message: response.reason.message }, onConfirm: bottomDrawerHelperActions.hide })),
                        onClose: () => reject(new Error(response.reason.message)),
                    });
                    return;
                }
                default:
                    bottomDrawerHelperActions.show({
                        duration: 5000,
                        component: (React.createElement(FallbackRejectionDrawer, { reason: response.reason, onConfirm: bottomDrawerHelperActions.hide })),
                        onClose: () => reject(new Error(response.reason.message)),
                    });
            }
        });
    });
    return [null, { handle }];
}
