import React, { useEffect } from 'react';
import { ActivityIndicator } from 'react-native';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import Column from 'src/components/10-atoms/layout/Column';
import Page from 'src/components/templates/page/Page.template';
import PageNavbar from 'src/components/templates/page/PageNavbar.template';
import useI18n from 'src/modules/translations/hooks/useI18n';
import styleUtils from 'src/utils/styles';
import EPBreadCrumbs from '../../components/EPBreadCrumbs';
import QuestionList from '../../containers/QuestionList';
import useData from './useData';
import { NodeType } from '../../graphql/common/types/node';
import usePlaylistIntro from 'src/lib/playlist/hooks/usePlaylistIntro';
import { pageAnalytics } from './analytics';
import QPContributor from './components/QPContributor';
import QPImagesView from './components/QPImagesView';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useIsSuperAdmin from 'src/modules/authentication/hooks/useIsSuperAdmin';
const QP = ({ navigation, route }) => {
    const { examLevel, subject, qp } = route.params;
    const [{ theme }] = useTheme();
    const { translate } = useI18n();
    const [state, actions] = useData();
    useEffect(() => {
        if (!qp) {
            actions.qpDetailsQuery.fetch();
        }
    }, []);
    const ancestorsDetails = state.qpDetailsQuery.data?.ep_qp.ancestorsDetails;
    const subjectDetails = subject ||
        ancestorsDetails?.writtenSubject ||
        ancestorsDetails?.oralsSubject;
    const qpDetails = qp || state.qpDetailsQuery.data?.ep_qp;
    const breadCrumbsLoading = !subject && state.qpDetailsQuery.loading;
    const handleSpeakPress = () => {
        actions.playlist.play();
    };
    usePlaylistIntro({
        onGetStarted() {
            pageAnalytics.setWidget('TTS_Intro').setTarget('Get_Started').click();
            handleSpeakPress();
        },
        onCancel() {
            pageAnalytics.setWidget('TTS_Intro').setTarget('Get_Started').click();
        },
    });
    const isSuperAdmin = useIsSuperAdmin();
    return (React.createElement(Page, null,
        React.createElement(Column, { bbw: 1 },
            React.createElement(PageNavbar, { backType: "back", title: translate('Question Paper'), onBackPress: navigation.goBack, actions: [
                    qpDetails?.type === NodeType.WRITTEN_QP && {
                        // eslint-disable-next-line react/no-unstable-nested-components
                        Icon(props) {
                            return state.playlist.playlistQuery.loading ? (React.createElement(ActivityIndicator, { size: "small" })) : (React.createElement(FeatherIcon, { name: "play-circle", ...props }));
                        },
                        title: 'Speak',
                        onPress() {
                            pageAnalytics.setWidget('Navbar').setTarget('TTS_Play').click();
                            handleSpeakPress();
                        },
                    },
                ] }),
            React.createElement(EPBreadCrumbs, { loading: breadCrumbsLoading, visibleCount: qpDetails?.type === NodeType.ORALS_QP ? 3 : 2, ancestorsDetails: ancestorsDetails, knownNodes: {
                    examLevel,
                    subject: subjectDetails,
                    qp: qpDetails,
                } })),
        React.createElement(QuestionList, { ListHeaderComponent: () => (React.createElement(Column, null,
                qpDetails?.author ? (React.createElement(QPContributor, { author: qpDetails.author, ctime: qpDetails.ctime })) : null,
                isSuperAdmin && qpDetails?.data.qpImages?.length && (React.createElement(QPImagesView, { bg: theme.palette.background.dark, data: qpDetails?.data.qpImages })))), style: styleUtils.containerHeight, variant: "question-paper", queryState: state.listQuery, queryActions: actions.listQuery, variables: state.listQueryVar, onQuestionPress: actions.gotoQuestionDetails })));
};
export default QP;
