import { getFilesFromFileList } from './utils';
export const dragEvent = {
    containsFiles(ev) {
        return (ev.dataTransfer?.files.length ?? 0) > 0;
    },
    getFiles(ev) {
        if (ev.dataTransfer?.files) {
            return getFilesFromFileList(ev.dataTransfer?.files);
        }
        return [];
    },
};
