export const getStyleForSize = (() => {
    const sizeMap = {
        xxs: 20,
        xs: 24,
        sm: 36,
        md: 48,
        jumbo: 128,
    };
    return (size) => ({
        minHeight: sizeMap[size],
        minWidth: sizeMap[size],
        borderRadius: sizeMap[size] / 2,
    });
})();
