import React from 'react';
import Pressable from 'src/components/10-atoms/pressables/Pressable';
export default function withPress(WrappedComponent, options = {}) {
    const WithPress = ({ onPress, pressableProps, pressableStyle, disabled, ...rest }, ref) => {
        if (onPress && !disabled) {
            const { style, ...otherProps } = pressableProps || {};
            const { touchFeedback = true } = options;
            return (React.createElement(Pressable, { touchFeedback: touchFeedback, onPress: onPress, disabled: disabled, style: [pressableStyle, style], ...otherProps },
                React.createElement(WrappedComponent, { ref: ref, disabled: disabled, ...rest })));
        }
        return React.createElement(WrappedComponent, { ref: ref, disabled: disabled, ...rest });
    };
    WithPress.displayName = `withPress(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
    return React.forwardRef(WithPress);
}
