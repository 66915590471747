export var ReviewEntityType;
(function (ReviewEntityType) {
    ReviewEntityType[ReviewEntityType["EP_WRITTEN_ANSWER"] = 0] = "EP_WRITTEN_ANSWER";
    ReviewEntityType[ReviewEntityType["EP_ORALS_ANSWER"] = 1] = "EP_ORALS_ANSWER";
    ReviewEntityType[ReviewEntityType["EP_WRITTEN_QP"] = 2] = "EP_WRITTEN_QP";
    ReviewEntityType[ReviewEntityType["EP_ORALS_QP"] = 3] = "EP_ORALS_QP";
})(ReviewEntityType || (ReviewEntityType = {}));
export var ReviewStatus;
(function (ReviewStatus) {
    ReviewStatus[ReviewStatus["PENDING"] = 0] = "PENDING";
    ReviewStatus[ReviewStatus["IN_REVIEW"] = 1] = "IN_REVIEW";
    ReviewStatus[ReviewStatus["COMPLETED"] = 2] = "COMPLETED";
})(ReviewStatus || (ReviewStatus = {}));
export var ReviewResult;
(function (ReviewResult) {
    ReviewResult[ReviewResult["SKIPPED"] = 1] = "SKIPPED";
    ReviewResult[ReviewResult["APPROVED"] = 2] = "APPROVED";
    ReviewResult[ReviewResult["REJECTED"] = 3] = "REJECTED";
})(ReviewResult || (ReviewResult = {}));
