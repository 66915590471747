export default function sortBy(arr, getValue, type) {
    return arr.slice().sort((a, b) => {
        const sortOrder = type === 'desc' ? -1 : 1;
        if (getValue(a) > getValue(b)) {
            return sortOrder;
        }
        else if (getValue(a) < getValue(b)) {
            return -sortOrder;
        }
        return 0;
    });
}
