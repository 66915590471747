import React from 'react';
import { ScrollView } from 'react-native';
import BreadCrumbsContainer from 'src/components/10-atoms/BreadCrumbs/Container';
import Button from 'src/components/10-atoms/button/Button';
import Field from 'src/components/10-atoms/form/Field';
import { getFieldError } from 'src/components/10-atoms/form/hooks/useFieldState/utils';
import DateInput from 'src/components/10-atoms/form/inputs/DateInput';
import FileInput from 'src/components/10-atoms/form/inputs/FileInput';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import Column from 'src/components/10-atoms/layout/Column';
import OverlayLayout from 'src/components/10-atoms/overlays/OverlayLayout';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import { bottomDrawerHelperActions } from 'src/components/20-helper-views/bottom-drawer/useBottomDrawer';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
import getBreadCrumbs from 'src/modules/exam-preparation/components/EPBreadCrumbs/getBreadCrumbs';
import AnswerSentForReviewDrawer from 'src/modules/exam-preparation/screens/Question/components/AnswerSentForReviewDrawer';
import useData from './useData';
import QPImagesGuidelines from './QPImagesGuidelines';
const TWENTY_YEARS = 20 * 365 * 24 * 60 * 60 * 1000;
const QPFormOverlay = ({ subjectID, onClose, onComplete, }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const [state, actions] = useData(subjectID);
    useEffectWithCtx(ctx => {
        if (ctx.state.uploadMutation.data) {
            ctx.actions.contributionResponseHandler.handle(ctx.state.uploadMutation.data.ep_uploadQP).then(ctx.onComplete).catch((error) => {
                console.log('Failed to upload QP:', error);
            });
        }
    }, { onComplete, actions, state }, [state.uploadMutation.data]);
    return (React.createElement(Column, { flex: true },
        React.createElement(OverlayLayout.FormHeader, { title: "Upload Question Paper", onClose: onClose }),
        React.createElement(Column, { flex: true },
            React.createElement(ScrollView, null,
                React.createElement(Column, { spacing: theme.spacing.standard.md, ph: theme.spacing.page, pv: theme.spacing.standard.sm },
                    React.createElement(Field, { label: "Subject" },
                        React.createElement(BreadCrumbsContainer, { loading: state.subjectDetailsQuery.loading, crumbs: getBreadCrumbs({}, {
                                ...state.subjectDetailsQuery.data?.ep_subjectDetails
                                    .ancestorsDetails,
                                subject: state.subjectDetailsQuery.data?.ep_subjectDetails,
                            }), visibleCount: 10 })),
                    React.createElement(Field, { label: "Date", error: getFieldError(state.form.fields.date.errors) },
                        React.createElement(DateInput, { placeholder: "Examination date", maxValue: new Date(), minValue: new Date(Date.now() - TWENTY_YEARS), value: state.form.fields.date.value, onChange: newValue => {
                                actions.form.fields.date.setValue(newValue);
                            } })),
                    React.createElement(Field, { label: "Question Paper Image(s)", error: getFieldError(state.form.fields.qpImages.errors) },
                        React.createElement(FileInput, { value: state.form.fields.qpImages.value, onChange: actions.form.fields.qpImages.setValue, maxCount: 20 }),
                        React.createElement(QPImagesGuidelines, null))),
                React.createElement(Column, { style: { height: 400 } }))),
        React.createElement(BottomDrawerLayout.Actions, { btw: 1, spacing: theme.spacing.standard.md, pv: theme.spacing.standard.sm },
            React.createElement(Button, { disabled: state.uploadMutation.loading, flex: true, variant: "outline", onPress: () => {
                    bottomDrawerHelperActions.show({
                        component: (React.createElement(AnswerSentForReviewDrawer, { onConfirm: bottomDrawerHelperActions.hide })),
                    });
                } }, "Cancel"),
            React.createElement(Button, { loading: state.uploadMutation.loading, flex: true, LeftIcon: props => React.createElement(FeatherIcon, { name: "save", ...props }), onPress: actions.handleSubmit }, "Submit"))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QPFormOverlay;
