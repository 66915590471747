import React, { useEffect, useLayoutEffect, useState } from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import ImageThumbnail from './ImageThumbnail';
import uploadFile from 'src/lib/file-storage/uploadFile';
import useStableCallback from 'src/hooks/useCallbackUtils/useStableCallback';
const ImageItem = ({ data, onRemove, onUploadStart, onUploadSuccess, onUploadFailure, }) => {
    const [{}] = useTheme(stylesGenerator);
    const { loading, error } = data.uploadRequest;
    const [src, setSrc] = useState(data.uploadRequest.data?.publicURL);
    useLayoutEffect(() => {
        if (!src) {
            // @ts-ignore
            setSrc(window.URL.createObjectURL(data.file.file));
        }
    }, []);
    const uploadImage = useStableCallback(() => {
        uploadFile(data.file)
            .then(response => {
            if (response?.data) {
                onUploadSuccess(data.id, response.data);
            }
            else {
                onUploadFailure(data.id, new Error('Failed to upload the image'));
            }
        })
            .catch(err => {
            onUploadFailure(data.id, err);
        });
    });
    useEffect(() => {
        if (!data.uploadRequest.data) {
            uploadImage();
        }
    }, []);
    const handleRetry = useStableCallback(() => {
        onUploadStart(data.id);
        uploadImage();
    });
    return (React.createElement(ImageThumbnail, { src: src, onRemove: () => {
            onRemove(data.id);
        }, loading: loading, error: error, onRetry: handleRetry }));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default ImageItem;
