import React from 'react';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import Button from 'src/components/10-atoms/button/Button';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Typography from 'src/components/10-atoms/typography/Typography';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import FitImage from 'src/components/10-atoms/image/FitImage';
import AppAssets from 'src/constants/appAssets';
const ThankContributorDrawer = ({ content, onConfirm, }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Body, { xAxis: "center", spacing: theme.spacing.standard.sm },
            React.createElement(Typography, { variant: TypographyVariant.PAGE_TITLE }, content.title),
            React.createElement(FitImage, { source: { uri: AppAssets.communityAddAnswerSuccess } }),
            React.createElement(Typography, { align: "center" }, "We salute your contributions on behalf of Marine Community")),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { flex: true, onPress: onConfirm }, "Ok"))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default ThankContributorDrawer;
