import { useCallback, useState } from 'react';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { ebGlobalChannel } from 'src/lib/events-bridge';
import useSubscription from 'src/lib/events-bridge/hooks/useSubscription';
import EBAPIs from 'src/lib/events-bridge/topics';
import { uniqueId } from 'lodash';
export default function useBottomDrawer() {
    const [state, setState] = useState({
        visible: false,
        component: null,
    });
    const show = useCallback((bottomDrawerData) => {
        if (bottomDrawerData.duration) {
            if (!bottomDrawerData.id) {
                bottomDrawerData.id = uniqueId();
            }
            setTimeout(() => {
                hide(bottomDrawerData.id);
            }, bottomDrawerData.duration);
        }
        setState({
            ...bottomDrawerData,
            visible: true,
        });
    }, []);
    const hide = useCallbackWithCtx((ctx, id) => {
        if (typeof id === 'string') {
            if (ctx.state.id !== id) {
                return;
            }
        }
        ctx.state.onClose && ctx.state.onClose();
        setState(prevState => ({
            // Retaining prevState, so that the component will be visible
            // during fade & slide animation
            ...prevState,
            visible: false,
            // component: null,
        }));
    }, { state });
    useSubscription(EBAPIs.helperViews.bottomDrawer.show, show);
    useSubscription(EBAPIs.helperViews.bottomDrawer.hide, hide);
    const actions = {
        show,
        hide,
    };
    return [state, actions];
}
export const bottomDrawerHelperActions = {
    show(data) {
        ebGlobalChannel.publish(EBAPIs.helperViews.bottomDrawer.show, data);
    },
    hide(id) {
        ebGlobalChannel.publish(EBAPIs.helperViews.bottomDrawer.hide, id);
    },
};
