import mime from 'mime';
import { extractFilename } from 'src/lib/file-storage/utils';
import { FileType } from './types';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
export function getMediaType(allowedFileType) {
    return allowedFileType === FileType.IMAGES ? 'photo' : null;
}
export const handleImagePickerResponse = (response) => {
    if (response.assets) {
        return response.assets.map((asset) => ({
            uri: asset.uri,
            filename: asset.fileName,
            type: asset.type,
            size: asset.fileSize,
        }));
    }
    else if (response.errorCode) {
        snackbarHelperActions.show({
            message: response.errorMessage ?? 'Something went wrong while opening Camera',
            variant: 'NEGATIVE',
            duration: 'MEDIUM',
        });
        return null;
    }
    return null;
};
export const handleImagePickerError = (error) => {
    snackbarHelperActions.show({
        message: error?.message ?? 'Something went wrong while opening Camera',
        variant: 'NEGATIVE',
        duration: 'MEDIUM',
    });
    return null;
};
export const convertExternalLinkToStandardLink = (link) => ({
    uri: link,
    filename: extractFilename(link),
    type: mime.getType(extractFilename(link)),
});
export function mapFileToFileResponse(file) {
    return {
        uri: '__UNAVAILABLE__',
        file,
        filename: file.name,
        type: file.type,
        size: file.size,
    };
}
export function getFilesFromFileList(fileList) {
    const result = [];
    for (const file of fileList) {
        result.push(mapFileToFileResponse(file));
    }
    return result;
}
