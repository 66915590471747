import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
const EP_UPLOAD_QP_MUTATION = gql `
  mutation EP_uploadQP($data: EPUploadQPInput!) {
    ep_uploadQP(data: $data) {
      sentForReview
      reason {
        message
        code
      }
    }
  }
`;
export function useEPUploadQPMutation(options) {
    return useGQLMutation(EP_UPLOAD_QP_MUTATION, options);
}
