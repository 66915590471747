import React, { useEffect, useRef } from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import useStableCallback from 'src/hooks/useCallbackUtils/useStableCallback';
import QuillEditor from './QuillEditor';
import { getDiffViewDelta } from './QuillEditor/utils/diff';
import { normalizeMathField } from './utils/mathField';
const HTMLDiffRenderer = ({ oldHtml: oldHtml, newHtml: newHtml, ...columnProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const editorRef = useRef(null);
    const quillRef = useRef();
    const updateEditorContent = useStableCallback(() => {
        const diffViewDelta = getDiffViewDelta(theme, quillRef.current, normalizeMathField(oldHtml ?? ''), normalizeMathField(newHtml ?? ''));
        editorRef.current?.setDelta(diffViewDelta);
    });
    const handleEditorChange = useCallbackWithCtx((ctx, quill) => {
        if (quill) {
            quillRef.current = quill;
            updateEditorContent();
        }
    }, { theme, oldHtml, newHtml });
    useEffect(() => {
        if (quillRef.current) {
            updateEditorContent();
        }
    }, [newHtml, oldHtml]);
    return (React.createElement(QuillEditor, { ref: editorRef, readOnly: true, initialValue: '', onEditorChanged: handleEditorChange, ...columnProps }));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default HTMLDiffRenderer;
