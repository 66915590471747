import uniqBy from 'lodash/uniqBy';
import React from 'react';
import Author from 'src/components/10-atoms/author/Author';
import AvatarStack from 'src/components/10-atoms/avatar/AvatarStack';
import TextButton from 'src/components/10-atoms/button/TextButton';
import HistoryIcon from 'src/components/10-atoms/icons/HistoryIcon';
import Row from 'src/components/10-atoms/layout/Row';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useI18n from 'src/modules/translations/hooks/useI18n';
const getUniqueAuthors = (answer) => {
    const authors = answer.data.answerHistory.map(item => item.author);
    return uniqBy(authors, 'id');
};
const AnswerContributors = ({ answer, onHistoryPress, ...rowProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const canShowAnswerHistory = answer.config?.WRITTEN_ANSWER?.contribution?.enableHistory ?? true;
    const showHistory = true || (canShowAnswerHistory && answer.data.answerHistory.length > 1);
    const uniqAuthors = getUniqueAuthors(answer);
    const contributorsAvatarUris = uniqAuthors.map(author => author.details.avatarURL);
    const author = uniqAuthors[0];
    const hasManyAuthors = uniqAuthors.length > 1;
    const hasManyUpdates = answer.data.answerHistory.length > 1;
    return (React.createElement(Row, { xAxis: "space-between", yAxis: "center", ph: theme.spacing.page, pv: theme.spacing.standard.sm, ...rowProps },
        hasManyAuthors ? (React.createElement(AvatarStack, { uris: contributorsAvatarUris, maxVisible: 6 })) : (React.createElement(Author, { flex: true, author: author })),
        hasManyUpdates && (React.createElement(Row, { yAxis: "center", spacing: theme.spacing.standard.md }, showHistory && (React.createElement(TextButton, { LeftIcon: props => (React.createElement(HistoryIcon, { ...props, color: theme.palette.primary.main })), onPress: onHistoryPress }, translate(answer.data.answerHistory.length)))))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default AnswerContributors;
