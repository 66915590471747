import React, { useEffect } from 'react';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import Tab from 'src/components/10-atoms/tabs/Tab';
import TabHeader from 'src/components/10-atoms/tabs/TabHeader';
import Tabs from 'src/components/10-atoms/tabs/Tabs';
import Page from 'src/components/templates/page/Page.template';
import PageNavbar from 'src/components/templates/page/PageNavbar.template';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import Analytics from 'src/lib/analytics';
import useI18n from 'src/modules/translations/hooks/useI18n';
import GenericServerError from 'src/components/10-atoms/GenericServerError';
import QueryHandler from 'src/components/10-atoms/QueryHandler';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { isWeb } from 'src/utils/platform';
import styleUtils from 'src/utils/styles';
import EPBreadCrumbs from '../../components/EPBreadCrumbs';
import QPTab from './container/QPTab';
import TopicList from './container/TopicList';
import useData from './useData';
const pageAnalytics = Analytics.event.builder().setPage('EP_Subject');
const Subject = ({ navigation, route }) => {
    const { subject, examLevel, subjectID } = route.params;
    const [state, actions] = useData(subjectID);
    const [{ styles }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    useEffect(() => {
        if (!subject) {
            actions.subjectDetailsQuery.fetch();
        }
    }, []);
    const ancestorsDetails = state.subjectDetailsQuery.data?.ep_subjectDetails.ancestorsDetails;
    const subjectDetails = subject || state.subjectDetailsQuery.data?.ep_subjectDetails;
    const examLevelDetails = examLevel || ancestorsDetails?.examLevel;
    const handleTopicPress = useCallbackWithCtx((ctx, topic) => {
        if (!ctx.subject) {
            return;
        }
        pageAnalytics.setWidget('TopicList').setTarget('Topic').click();
        navigation.navigate('EP_TopicQuestions', {
            ...(!isWeb && {
                examLevel: ctx.examLevel,
                subject: ctx.subject,
            }),
            ancestorID: ctx.subject.id,
            topic: topic.id,
        });
    }, {
        subject: subjectDetails,
        examLevel: examLevelDetails,
    });
    const handleQPPress = useCallbackWithCtx((ctx, questionPaper) => {
        pageAnalytics
            .setWidget('QuestionPaperList')
            .setTarget('QuestionPaper')
            .click();
        navigation.navigate('EP_QP', {
            ...(!isWeb && {
                examLevel: ctx.examLevel,
                subject: ctx.subject,
                qp: questionPaper,
            }),
            qpID: questionPaper.id,
        });
    }, { examLevel: examLevelDetails, subject: subjectDetails });
    const breadCrumbsLoading = !examLevel && state.subjectDetailsQuery.loading;
    return (React.createElement(Page, null,
        React.createElement(Column, { bbw: 1 },
            React.createElement(PageNavbar, { backType: "back", title: translate('Subject'), onBackPress: navigation.goBack }),
            React.createElement(Row, { yAxis: "flex-end", spacing: 24, style: [styles.header, styleUtils.pagePadding] },
                React.createElement(EPBreadCrumbs, { loading: breadCrumbsLoading, visibleCount: 1, knownNodes: {
                        examLevel: examLevelDetails,
                        subject: subjectDetails,
                    }, ph: 0, flex: true }),
                React.createElement(TabHeader, { tabs: state.tabs.tabs, activeIndex: state.tabs.activeIndex, onChange: (index) => {
                        pageAnalytics
                            .setWidget('GroupBy')
                            .setTarget(state.tabs.tabs[index].id)
                            .click();
                        actions.tabs.setActiveIndex(index);
                    } }))),
        React.createElement(QueryHandler, { skip: !!subjectDetails, queryState: state.subjectDetailsQuery, LoaderComponent: null, ErrorComponent: React.createElement(GenericServerError, { onRetry: actions.subjectDetailsQuery.fetch }), DataComponent: React.createElement(Tabs, { activeTab: state.tabs.tabs[state.tabs.activeIndex]?.id },
                React.createElement(Tab, { id: "PAPERS" },
                    React.createElement(QPTab, { subject: subjectDetails, subjectID: subjectID, onQPPress: handleQPPress })),
                React.createElement(Tab, { id: "TOPICS" },
                    React.createElement(TopicList, { subjectID: subjectID, onTopicPress: handleTopicPress }))) })));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    header: {
        backgroundColor: theme.palette.background.main,
    },
}));
export default Subject;
