import React from 'react';
import { TypographyFontWeight, TypographyVariant, } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import withPress from 'src/hoc/withPress';
import useUserStorage from 'src/modules/authentication/storage/user/hook';
import Avatar from '../avatar/Avatar';
import Column from '../layout/Column';
import Row from '../layout/Row';
import Typography from '../typography/Typography';
import { getAuthorFullName } from './utils';
const Author = ({ author, ...rowProps }) => {
    const [{ theme }] = useTheme();
    const [userDetails] = useUserStorage();
    const isAuthor = userDetails?.id === author.id;
    const fullName = getAuthorFullName(author);
    return (React.createElement(Row, { yAxis: "center", spacing: theme.spacing.standard.xs, ...rowProps },
        React.createElement(Avatar, { uri: author.details.avatarURL, size: "sm" }),
        React.createElement(Column, { yAxis: "center", flex: true },
            React.createElement(Typography, { numberOfLines: 1, ellipsizeMode: "tail", variant: TypographyVariant.CAPTION, fontWeight: isAuthor
                    ? TypographyFontWeight.MEDIUM
                    : TypographyFontWeight.REGULAR, lineHeight: 16 }, isAuthor ? 'YOU' : fullName),
            !isAuthor && (React.createElement(Typography, { numberOfLines: 1, ellipsizeMode: "tail", variant: TypographyVariant.CAPTION, color: theme.palette.typography.secondary, lineHeight: 16 },
                "@",
                author.details.userName)))));
};
export default withPress(Author);
