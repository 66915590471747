const FTUEKeys = {
    NOTIFICATION_ONBOARDING: 'notification/home',
    COMMUNITY_ONBOARADING: 'community/onboarding',
    COMMUNITY_QUESTION_GUIDELINES: 'community/question_guidelines',
    COMMUNITY_ANSWER_GUIDELINES: 'community/answer_guidelines',
    COMMUNITY_QUESTION_REPLY_GUIDELINES: 'community/question_reply_guidelines',
    COMMUNITY_ANSWER_REPLY_GUIDELINES: 'community/answer_reply_guidelines',
    COMMUNITY_FIRST_QUESTION_READ: 'community/first_question_read',
    COMMUNITY_FIRST_QUESTION_READ_AS_AUTHOR: 'community/first_question_read_as_author',
    COMMUNITY_QUESTION_VOTING_GUIDELINES: 'community/question_voting_guidelines',
    COMMUNITY_ANSWER_VOTING_GUIDELINES: 'community/answer_voting_guidelines',
    COMMUNITY_QUESTIONS_NEEDING_HELP_FILTER_EXPLANATION: 'community/questions_needing_help_filter_explanation',
    COMMUNITY_TOPICS_GROUP_FILTER_EXPLANATION: 'community/topics_group_filter_explanation',
    EP_WRITTEN_ANSWER_HISTORY_WALKTHROUGH: 'ep/written_answer_history_walkthrough',
    EP_ANSWER_CONTRIBUTION_INTRODUCTION: 'ep/answer_contribution_introduction',
    PLAYLIST_INTRODUCTION: 'playlist/introduction',
    EP_QP_UPLOAD_INTRODUCTION: 'ep/qp/upload/introduction'
};
export default FTUEKeys;
