import { ApolloClient, ApolloLink, createHttpLink, from, } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import appEvents from 'src/modules/10-app/events';
import tokenStorage from 'src/modules/authentication/storage/token/storage';
import { getServerURL } from 'src/modules/debug/utils';
import userPreferencesStorage from 'src/modules/preferences/storage';
import { getCachedDeviceInfo } from '../device';
import graphQLCache from './cache';
const httpLink = createHttpLink({
    uri: `${getServerURL()}/graphql`,
});
const authLink = new ApolloLink((operation, forward) => {
    const token = tokenStorage.getToken();
    operation.setContext({
        headers: {
            authorization: token ? `Bearer ${token}` : '',
        },
    });
    return forward(operation).map(data => {
        const context = operation.getContext();
        if (context.response.headers) {
            const newToken = context.response.headers.get('new-token');
            if (newToken) {
                tokenStorage.setToken(newToken);
            }
        }
        return data;
    });
});
const studioClientIdentificationLink = setContext(async (_, context) => {
    const deviceInfo = await getCachedDeviceInfo();
    return {
        ...context,
        headers: {
            ...context.headers,
            'apollographql-client-name': deviceInfo.osType,
            'apollographql-client-version': [
                deviceInfo.appVersion,
                deviceInfo.codePushVersion,
            ]
                .filter(Boolean)
                .join('_'),
        },
    };
});
const handleInvalidTokenLink = onError(({ networkError, graphQLErrors }) => {
    if (networkError && networkError.statusCode === 401) {
        console.log('[LOGOUT] Received Response Status 401');
        appEvents.onLogout();
    }
    if (graphQLErrors && graphQLErrors.length > 0) {
        const unauthenticated = graphQLErrors.some(gqlError => gqlError.extensions?.code === 'UNAUTHENTICATED');
        if (unauthenticated) {
            console.log('[LOGOUT] Error: UNAUTHENTICATED');
            appEvents.onLogout();
        }
    }
});
const acceptLanguageLink = setContext((_, context) => {
    const lang = userPreferencesStorage.getPrefs().lang;
    if (!lang) {
        return context;
    }
    return {
        ...context,
        headers: {
            ...context.headers,
            'accept-language': lang,
        },
    };
});
const retryLink = new RetryLink({
    delay: {
        initial: 300,
        max: 2000,
        jitter: true,
    },
    attempts: {
        max: 3,
        retryIf(error) {
            return (error?.statusCode ?? 500) >= 500;
        },
    },
});
export const graphqlClient = new ApolloClient({
    link: from([
        authLink,
        studioClientIdentificationLink,
        handleInvalidTokenLink,
        acceptLanguageLink,
        retryLink,
        httpLink,
    ].filter(Boolean)),
    cache: graphQLCache,
});
