import React from 'react';
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Typography from '../typography/Typography';
const AnimatedTypography = Animated.createAnimatedComponent(Typography);
const HelperText = ({ message }) => {
    const [{ theme }] = useTheme();
    const textAnimatedStyle = useAnimatedStyle(() => {
        return {
            opacity: withTiming(message ? 1 : 0),
        };
    }, [message]);
    return (React.createElement(AnimatedTypography, { variant: TypographyVariant.BADGE, color: theme.palette.negative.main, style: textAnimatedStyle }, message));
};
export default HelperText;
