import React from 'react';
import { View } from 'react-native';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import Pressable from 'src/components/10-atoms/pressables/Pressable';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
export default function withRemove(Component) {
    const WithRemove = ({ onRemove, ...props }) => {
        return (React.createElement(View, null,
            React.createElement(Component, { ...props }),
            onRemove && React.createElement(RemoveBtn, { onPress: onRemove })));
    };
    return WithRemove;
}
const RemoveBtn = ({ style, ...pressableProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    return (React.createElement(Pressable, { style: [styles.removeContainer, style], ...pressableProps },
        React.createElement(FeatherIcon, { name: "x", size: theme.iconSize.xs })));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    removeContainer: {
        position: 'absolute',
        right: -8,
        top: -8,
        width: 24,
        height: 24,
        borderRadius: 24,
        backgroundColor: theme.palette.background.darker,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
