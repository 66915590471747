import React, { useEffect } from 'react';
import Animated, { Easing, useAnimatedStyle, useSharedValue, withDelay, withRepeat, withSequence, withTiming, } from 'react-native-reanimated';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Row from '../layout/Row';
import withDefaultIconProps from './withDefaultIconProps';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import NumberUtils from 'src/utils/number';
const AudioLines = ({ color, size, initialDelay }) => {
    const randomHeight = useSharedValue(NumberUtils.random(0.1 * size, size));
    useEffect(() => {
        const timer = setInterval(() => {
            const random = NumberUtils.random(0.1 * size, size);
            randomHeight.value = withDelay(initialDelay, withRepeat(withSequence(withTiming(0), withTiming(random, {
                duration: 500,
                easing: Easing.linear,
            })), -1, true));
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);
    const [{ styles }] = useTheme(stylesGenerator, { size }, [size]);
    const heightStyle = useAnimatedStyle(() => {
        return {
            height: randomHeight.value,
        };
    }, [randomHeight]);
    return (React.createElement(Animated.View, { style: [
            heightStyle,
            {
                backgroundColor: color,
            },
            styles.line,
        ] }));
};
const AudioIcon = ({ color, size, style, height: _height, ...rest }) => {
    const numOfLines = Math.floor(size /
        5 /* 2(line width) + 2(spacing) + 1 (to reduce the number of lines) */);
    const [{ theme, styles }] = useTheme(stylesGenerator, { size }, [size]);
    return (React.createElement(Row, { xAxis: "center", spacing: 2, yAxis: "center", ph: theme.spacing.standard.xxs, style: [styles.container, style], ...rest }, new Array(numOfLines).fill(0).map((_, i) => (React.createElement(AudioLines, { key: i, initialDelay: i * 100, color: color, size: size - theme.spacing.standard.xs })))));
};
const stylesGenerator = createThemedStyle(({}, { size }) => ({
    container: {
        height: size,
        width: size,
    },
    line: {
        width: 2,
    },
}));
export default withDefaultIconProps(AudioIcon);
