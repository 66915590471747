import React, { useMemo } from 'react';
import { ScrollView } from 'react-native';
import Chip from 'src/components/10-atoms/chips/Chip';
import Label from 'src/components/10-atoms/form/Label';
import HistoryIcon from 'src/components/10-atoms/icons/HistoryIcon';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import Pressable from 'src/components/10-atoms/pressables/Pressable';
import Typography from 'src/components/10-atoms/typography/Typography';
import { TypographyFontWeight, TypographyVariant, } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useI18n from 'src/modules/translations/hooks/useI18n';
import TranslatedIndicator from 'src/modules/translations/components/TranslatedIndicator';
import isTranslatedDoc from 'src/modules/translations/utils/isTranslatedDoc';
import sortBy from 'src/utils/array/sortBy';
import QuestionTopic from './QuestionTopic';
import { NodeType } from '../../graphql/common/types/node';
import { getQuestionLabel } from './utils';
import EPBreadCrumbs from '../../components/EPBreadCrumbs';
import HTMLRenderer from 'src/components/10-atoms/form/inputs/editor/html/HTMLEditor/Renderer';
const QuestionItem = ({ variant, showQuestionLabel = true, data: question, style, onPress, ...colProps }) => {
    const hasDuplicates = (question.duplicates?.length ?? 0) > 0;
    const [{ styles, theme }] = useTheme(stylesGenerator, { hasDuplicates }, [
        hasDuplicates,
    ]);
    const { translate } = useI18n();
    const duplicates = useMemo(() => {
        return sortBy(question.duplicates ?? [], (item) => item.questionPaper?.data.dateNL ?? '', 'desc');
    }, [question.duplicates]);
    const isTranslated = isTranslatedDoc(question.translationInfo);
    const label = showQuestionLabel ? getQuestionLabel(question) : 'Q';
    const isWrittenQuestion = question.type === NodeType.WRITTEN_QUESTION;
    const ancestorsDetails = question.ancestorsDetails;
    const showBreadCrumbs = variant === 'question-search' && ancestorsDetails;
    return (React.createElement(Column, { style: [styles.container, style], spacing: theme.spacing.standard.xxs, ...colProps },
        React.createElement(Pressable, { onPress: onPress },
            React.createElement(Column, null,
                showBreadCrumbs && (React.createElement(EPBreadCrumbs, { loading: false, visibleCount: 5, ancestorsDetails: ancestorsDetails })),
                React.createElement(Row, { spacing: 4, pr: theme.spacing.page },
                    React.createElement(Column, { xAxis: "flex-end", spacing: theme.spacing.standard.xs, style: [
                            styles.metaSection,
                            question.data.isSubQuestion && {
                                width: 60,
                            },
                        ] },
                        React.createElement(Typography, { color: theme.palette.typography.secondary }, label),
                        isTranslated && React.createElement(TranslatedIndicator, null)),
                    React.createElement(Column, { flex: true, spacing: theme.spacing.standard.xxs },
                        React.createElement(HTMLRenderer, { html: question.data.questionHTML, flex: true, typographyVariant: TypographyVariant.BODY, typographyFontWeight: variant === 'question-details'
                                ? TypographyFontWeight.MEDIUM
                                : TypographyFontWeight.REGULAR }),
                        !!question.data.topics?.length && (React.createElement(Row, { wrap: true, spacing: theme.spacing.standard.xxs, style: styles.topics }, question.data.topics.map(topic => (React.createElement(QuestionTopic, { key: topic, topic: topic }))))),
                        isWrittenQuestion && (React.createElement(Row, { xAxis: "space-between" },
                            React.createElement(Typography, { color: theme.palette.typography.secondary, variant: TypographyVariant.CAPTION }, translate('%marks marks', {
                                marks: question.data.marks,
                            })),
                            !!question.questionPaper?.data.label && (React.createElement(Typography, { color: theme.palette.typography.secondary, variant: TypographyVariant.CAPTION }, question.questionPaper.data.label)))))))),
        isWrittenQuestion && hasDuplicates && (React.createElement(ScrollView, { style: styles.duplicatesContainer, contentContainerStyle: styles.duplicatesContentContainer, horizontal: true, showsHorizontalScrollIndicator: false },
            React.createElement(Row, { yAxis: "center", pv: theme.spacing.standard.xs, spacing: theme.spacing.itemSeparation },
                React.createElement(Row, { yAxis: "center", spacing: theme.spacing.standard.xxxs },
                    React.createElement(HistoryIcon, { size: theme.iconSize.xs }),
                    React.createElement(Label, null, question.duplicates.length)),
                React.createElement(Row, { yAxis: "center", spacing: theme.spacing.standard.xs }, duplicates.map((duplicate, i) => (React.createElement(Chip, { key: duplicate.questionPaper?.id ?? i, variant: "non-pressable", selected: false }, duplicate.questionPaper?.data.label)))))))));
};
const stylesGenerator = createThemedStyle(({ theme }, { hasDuplicates }) => ({
    container: {
        paddingTop: theme.spacing.standard.xs,
        paddingBottom: hasDuplicates ? 0 : theme.spacing.standard.xs,
    },
    metaSection: {
        width: 40,
    },
    topics: {
        marginTop: theme.spacing.standard.xxs,
    },
    topicsList: {
        alignItems: 'center',
    },
    duplicatesContainer: {
        backgroundColor: theme.palette.background.subtle,
    },
    duplicatesContentContainer: {
        paddingLeft: 48,
        paddingRight: theme.spacing.page,
    },
}));
export default React.memo(QuestionItem);
