import React from 'react';
import { getAuthorFullName } from 'src/components/10-atoms/author/utils';
import Row from 'src/components/10-atoms/layout/Row';
import { PRESSABLE_SIZE } from 'src/components/10-atoms/pressables/constants';
import TypographyBuilder from 'src/components/10-atoms/typography/TypographyBuilder';
import { TypographyFontWeight, TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import { getReadableDate } from 'src/utils/date';
const QPContributor = ({ author, ctime, ...rowProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(Row, { xAxis: "flex-start", yAxis: "center", ph: theme.spacing.page, pv: theme.spacing.standard.xxs, bg: theme.palette.background.message, spacing: theme.spacing.standard.xxs, style: { minHeight: PRESSABLE_SIZE }, ...rowProps },
        React.createElement(TypographyBuilder, { variant: TypographyVariant.CAPTION, builder: [
                {
                    text: getAuthorFullName(author),
                    fontWeight: TypographyFontWeight.MEDIUM
                },
                {
                    text: 'uploaded this question paper',
                },
                {
                    text: ` •  ${getReadableDate(new Date(ctime), 'relative')}`,
                    color: theme.palette.typography.secondary,
                },
            ] })));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QPContributor;
