import React, { useEffect, useState } from 'react';
import RNZoomImageViewer from 'react-native-image-zoom-viewer';
import CloseIcon from 'src/components/10-atoms/icons/CloseIcon';
import ModalBase from 'src/components/10-atoms/modals/ModalBase';
import PressableArea from 'src/components/templates/PressableArea';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
import { clamp } from 'src/utils/math';
import ImageThumbnailList from './ImageThumbnailList';
const ImageViewer = ({ images, initialIndex, visible, onClose, }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const [index, setIndex] = useState(initialIndex);
    useEffect(() => {
        setIndex(initialIndex);
    }, [initialIndex]);
    useEffectWithCtx((_, ctxRef) => {
        if (images.length > 1) {
            const handler = (ev) => {
                let newIndex = ctxRef.current.index;
                switch (ev.key) {
                    case 'ArrowRight':
                        newIndex += 1;
                        break;
                    case 'ArrowLeft':
                        newIndex -= 1;
                        break;
                }
                setIndex(clamp(newIndex, 0, images.length - 1));
            };
            document.addEventListener('keydown', handler);
            return () => {
                document.removeEventListener('keydown', handler);
            };
        }
    }, { index }, [images]);
    return (React.createElement(ModalBase, { animationIn: "zoomIn", animationOut: "zoomOut", propagateSwipe: false, isVisible: visible, onDismiss: onClose },
        React.createElement(RNZoomImageViewer, { index: index, imageUrls: images.map(image => ({ url: image.uri })), enableSwipeDown: true, onSwipeDown: onClose, swipeDownThreshold: 100, backgroundColor: theme.palette.background.dark, renderIndicator: () => React.createElement(React.Fragment, null), footerContainerStyle: { width: '100%' }, renderFooter: images.length > 1 ? (index) => (React.createElement(ImageThumbnailList, { index: index, images: images, onThumbnailPress: setIndex })) : undefined }),
        React.createElement(PressableArea, { style: [styles.close], xAxis: "flex-end" },
            React.createElement(CloseIcon, { onPress: onClose, color: "white" }))));
};
const stylesGenerator = createThemedStyle(({ dimensions: { safeAreaInsets } }) => ({
    close: {
        position: 'absolute',
        backgroundColor: 'rgba(0,0,0,0.3)',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 24,
        right: 12,
        top: safeAreaInsets.top + 12,
    },
}));
export default ImageViewer;
