import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Row from 'src/components/10-atoms/layout/Row';
import RowCenter from 'src/components/10-atoms/layout/RowCenter';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import Typography from 'src/components/10-atoms/typography/Typography';
import Button from 'src/components/10-atoms/button/Button';
import RemoteIcon from 'src/components/10-atoms/icons/RemoteIcon';
import AppAssets from 'src/constants/appAssets';
import { PRESSABLE_SIZE } from 'src/components/10-atoms/pressables/constants';
import { Linking } from 'react-native';
import config from 'react-native-ultimate-config';
const JoinHiringChat = ({ ...rowProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const handleChatPress = () => {
        Linking.openURL(config.HIRING_CHAT_ROOM_INVITE_LINK);
    };
    return (React.createElement(Row, { xAxis: "space-between", yAxis: "center", ph: theme.spacing.page, bg: theme.palette.background.message, height: PRESSABLE_SIZE, ...rowProps },
        React.createElement(RowCenter, { spacing: theme.spacing.standard.xs },
            React.createElement(FeatherIcon, { name: "briefcase", size: theme.iconSize.xs }),
            React.createElement(Typography, null, "On a job hunt?")),
        React.createElement(Button, { variant: "outline", size: "sm", btnStyle: { backgroundColor: theme.palette.background.main }, onPress: handleChatPress, LeftIcon: props => (React.createElement(RemoteIcon, { uri: AppAssets.telegramIcon, ...props })) }, "Join")));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default JoinHiringChat;
