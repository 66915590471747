import React, { useState } from 'react';
import { ActivityIndicator, Platform, StyleSheet, View, } from 'react-native';
import { PRESSABLE_SIZE } from 'src/components/templates/PressableArea';
import { TypographyFontWeight } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import { renderNamedSlot } from 'src/utils/component';
import styleUtils from 'src/utils/styles';
import Row from '../layout/Row';
import RowCenter from '../layout/RowCenter';
import Typography from '../typography/Typography';
import { getButtonHeight, getButtonTextVariant, getContrastColor, getMainColor, } from './utils';
import Pressable from '../pressables/Pressable';
import { isWeb } from 'src/utils/platform';
const Button = ({ colorScheme = 'normal', variant = 'contained', disableSAB = false, loading = false, size = 'md', LeftIcon, RightIcon, children, textStyle, noIconSpacing = true, textContainerStyle, btnStyle, style, disabled = false, onPress, flex, ...rest }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator, {
        LeftIcon,
        RightIcon,
        size,
        variant,
        colorScheme,
        disableSafeAreaBottomOffset: disableSAB,
        loading,
        children,
    }, [
        size,
        LeftIcon,
        RightIcon,
        variant,
        colorScheme,
        disableSAB,
        loading,
        children,
    ]);
    const [pressActive, setPressActive] = useState(false);
    function renderText() {
        if (!children)
            return null;
        return typeof children === 'string' ? (React.createElement(RowCenter, { style: [styles.btnTextContainer, textContainerStyle] },
            React.createElement(Typography, { fontWeight: TypographyFontWeight.MEDIUM, variant: getButtonTextVariant(size), style: [
                    styles.btnText,
                    styles[`${variant}BtnText`],
                    disabled && styles.disabledBtnText,
                    textStyle,
                ] }, children))) : typeof children === 'function' ? (children({
            textStyle: [
                styles.btnText,
                styles[`${variant}BtnText`],
                disabled && styles.disabledBtnText,
                textStyle,
            ],
            disabled,
        })) : (children);
    }
    function renderLoader() {
        return React.createElement(ActivityIndicator, { size: 24, color: theme.palette.primary.main });
    }
    function getIconProps() {
        return {
            color: disabled
                ? theme.palette.pressable.disabledText
                : variant === 'contained'
                    ? getContrastColor(theme, colorScheme)
                    : getMainColor(theme, colorScheme, variant),
            size: theme.iconSize[size ?? 'sm'],
            disabled,
        };
    }
    disabled = disabled || loading;
    return (React.createElement(Pressable, { style: [styles.btnShell, flex && styleUtils.flex, style], ...(!isWeb
            ? {
                onPressIn() {
                    setPressActive(true);
                },
                onPressOut() {
                    setPressActive(false);
                },
            }
            : {
                touchFeedback: true,
            }), hitSlop: {
            top: 4,
            bottom: 4,
            left: 0,
            right: 0,
        }, disabled: disabled, onPress: onPress },
        React.createElement(Row, { xAxis: "space-between", yAxis: "center", style: [
                styles.button,
                styles[`${variant}Btn`],
                btnStyle,
                disabled && styles.disabledBtn,
            ], ...rest },
            loading ? (renderLoader()) : (React.createElement(React.Fragment, null,
                (LeftIcon || !noIconSpacing) && (React.createElement(RowCenter, { style: styles.icon }, renderNamedSlot(LeftIcon, getIconProps()))),
                renderText(),
                (RightIcon || !noIconSpacing) && (React.createElement(RowCenter, { style: styles.icon }, renderNamedSlot(RightIcon, getIconProps()))))),
            pressActive && React.createElement(View, { style: styles.pressActive }))));
};
export const BTN_HEIGHT = PRESSABLE_SIZE;
export const FLOATING_BTN_BOTTOM_OFFSET = 32 + BTN_HEIGHT;
const stylesGenerator = createThemedStyle(({ theme, dimensions, select }, { size, LeftIcon, RightIcon, variant, colorScheme, disableSAB, loading, children, }) => {
    const height = getButtonHeight(size);
    return {
        btnShell: {
            minHeight: height,
            overflow: variant === 'float' ? 'visible' : 'hidden',
            ...(variant === 'float' && {
                overflow: 'visible',
                position: 'absolute',
                bottom: (disableSAB ? 0 : dimensions.safeAreaInsets.bottom) +
                    theme.spacing.page,
                right: theme.spacing.page,
            }),
        },
        button: {
            overflow: variant === 'float' ? 'visible' : 'hidden',
            minHeight: height,
            borderRadius: height / 2,
            paddingLeft: !children
                ? 0
                : !loading && LeftIcon
                    ? 0
                    : theme.spacing.standard.md,
            paddingRight: !children
                ? 0
                : !loading && RightIcon
                    ? 0
                    : theme.spacing.standard.md,
            justifyContent: 'center',
            alignItems: 'center',
            ...(variant === 'float' &&
                Platform.select({
                    ios: {
                        shadowColor: '#000',
                        shadowOpacity: 0.2,
                        shadowRadius: 2,
                        shadowOffset: {
                            height: 1,
                            width: 0,
                        },
                    },
                    android: {
                        borderWidth: 1,
                        borderColor: theme.palette.border.main,
                        elevation: 3,
                    },
                    web: {
                        boxShadow: 'rgba(20, 20, 20, 0.2) 0px 4px 9px',
                    },
                })),
        },
        btnTextContainer: {},
        btnText: {
            textAlign: 'center',
            textTransform: 'uppercase',
        },
        icon: {
            width: height,
            height,
        },
        pressActive: {
            ...StyleSheet.absoluteFillObject,
            borderRadius: height / 2,
            backgroundColor: theme.palette.pressable.active,
        },
        disabledBtn: {
            backgroundColor: theme.palette.pressable.disabled,
            borderColor: theme.palette.pressable.disabled,
        },
        disabledBtnText: {
            color: theme.palette.pressable.disabledText,
        },
        containedBtn: {
            backgroundColor: getMainColor(theme, colorScheme),
        },
        containedBtnText: {
            color: getContrastColor(theme, colorScheme),
        },
        floatBtn: {
            backgroundColor: select({
                light: theme.palette.background.main,
                dark: theme.palette.background.darker,
            }),
        },
        floatBtnText: {
            color: theme.palette.primary.dark,
        },
        outlineBtn: {
            borderWidth: 1,
            borderColor: getMainColor(theme, colorScheme),
        },
        outlineBtnText: {
            color: theme.palette.typography.primary,
        },
        textBtn: {
            backgroundColor: 'transparent',
            paddingHorizontal: 8,
        },
        textBtnText: {
            color: getMainColor(theme, colorScheme, variant),
        },
    };
});
export default Button;
