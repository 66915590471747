import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import ULItemLayout from 'src/components/10-atoms/content/ULItemLayout';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import FitImage from 'src/components/10-atoms/image/FitImage';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import CustomScrollView from 'src/components/10-atoms/list-views/CustomScrollView';
import Typography from 'src/components/10-atoms/typography/Typography';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import AppAssets from 'src/constants/appAssets';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import styleUtils from 'src/utils/styles';
const QPUploadIntroDrawer = ({ onDismiss }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, "Question paper upload \uD83C\uDF89\uD83C\uDF89\uD83C\uDF89"),
        React.createElement(CustomScrollView, { containerStyle: styleUtils.flex },
            React.createElement(BottomDrawerLayout.Body, { mb: theme.spacing.standard.md },
                React.createElement(FitImage, { source: { uri: AppAssets.qpUploadIntro } }),
                React.createElement(Column, null,
                    React.createElement(Column, { spacing: theme.spacing.standard.sm },
                        React.createElement(Typography, null, "Upload missing question papers in 3 simple steps:"),
                        React.createElement(ULItemLayout, null,
                            React.createElement(Row, { yAxis: 'center', spacing: theme.spacing.standard.xs },
                                React.createElement(FeatherIcon, { name: "calendar" }),
                                React.createElement(Typography, null, "Select the Examination date"))),
                        React.createElement(ULItemLayout, null,
                            React.createElement(Row, { yAxis: 'center', spacing: theme.spacing.standard.xs },
                                React.createElement(FeatherIcon, { name: "camera" }),
                                React.createElement(Typography, null, "Upload Question paper images"))),
                        React.createElement(ULItemLayout, null,
                            React.createElement(Row, { yAxis: 'center', spacing: theme.spacing.standard.xs },
                                React.createElement(FeatherIcon, { name: "check-circle" }),
                                React.createElement(Typography, null, "Submit"))))))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { flex: true, onPress: onDismiss }, "Got it!"))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QPUploadIntroDrawer;
