import React, { useRef } from 'react';
import { FlatList } from 'react-native-gesture-handler';
import ImageThumbnail from 'src/components/10-atoms/form/inputs/FileInput/ImageThumbnail';
import { THUMBNAIL_SIZE } from 'src/components/10-atoms/form/inputs/FileInput/ThumbnailLayout';
import Row from 'src/components/10-atoms/layout/Row';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
const ImageThumbnailList = ({ index, images, onThumbnailPress }) => {
    const [{ dimensions, theme }] = useTheme(stylesGenerator);
    const flatlistRef = useRef(null);
    useEffectWithCtx((ctx) => {
        flatlistRef.current?.scrollToIndex({
            animated: true,
            index,
            viewOffset: dimensions.viewPort.width / 2 - THUMBNAIL_SIZE / 2
        });
    }, {}, [index]);
    return (React.createElement(Row, { bg: theme.palette.background.dark, ph: theme.spacing.page, pv: theme.spacing.standard.xs },
        React.createElement(FlatList, { horizontal: true, ref: flatlistRef, data: images, renderItem: ({ item, index: i }) => (React.createElement(ImageThumbnail, { src: item.uri, bcl: i === index
                    ? theme.palette.primary.main
                    : theme.palette.border.main, onPress: () => onThumbnailPress(i) })), getItemLayout: (_, index) => ({
                index,
                length: THUMBNAIL_SIZE,
                offset: index * (THUMBNAIL_SIZE + theme.spacing.standard.xs),
            }), ItemSeparatorComponent: () => React.createElement(Row, { style: { width: theme.spacing.standard.xs } }) })));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default ImageThumbnailList;
