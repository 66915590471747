import { format } from 'date-fns';
import { EnglishContext } from 'src/modules/translations/context';
import sortBy from 'src/utils/array/sortBy';
import { appendTimeZone } from 'src/utils/date';
export const groupQPList = (() => {
    const groupByFormat = {
        year: 'yyyy',
        month: 'MMM yyyy',
    };
    return (questionPapers = [], groupBy, langCode = EnglishContext.code) => {
        const groups = Object.values(questionPapers.reduce((result, qp) => {
            const groupName = format(new Date(appendTimeZone(qp.data.dateNL)), groupByFormat[groupBy]);
            if (!result[groupName]) {
                result[groupName] = {
                    id: groupName,
                    label: groupName,
                    sortBy: qp.data.dateNL,
                    questionPapers: [],
                };
            }
            result[groupName].questionPapers.push(qp);
            return result;
        }, {}));
        return sortBy(groups, item => item.sortBy, 'desc');
    };
})();
