import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import Column from 'src/components/10-atoms/layout/Column';
import { overlayHelperViewActions } from 'src/components/20-helper-views/overlay/useOverlay';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
import useFTUE from 'src/lib/ftue/hooks/useFTUE';
import FTUEKeys from 'src/lib/ftue/keys';
import { NodeType } from 'src/modules/exam-preparation/graphql/common/types/node';
import styleUtils from 'src/utils/styles';
import QPList from '../QPList';
import QPFormOverlay from './QPForm';
import { bottomDrawerHelperActions } from 'src/components/20-helper-views/bottom-drawer/useBottomDrawer';
import QPUploadIntroDrawer from '../../components/IntroDrawer';
const QPTab = ({ subjectID, subject, onQPPress, style, ...columnProps }) => {
    const [{}] = useTheme(stylesGenerator);
    const handleAddQP = useCallbackWithCtx(ctx => {
        overlayHelperViewActions.show({
            component: (React.createElement(QPFormOverlay, { subjectID: ctx.subjectID, onClose: overlayHelperViewActions.hide, onComplete: overlayHelperViewActions.hide })),
        });
    }, { subjectID });
    const [isFTUE, ftueActions] = useFTUE(FTUEKeys.EP_QP_UPLOAD_INTRODUCTION);
    useEffectWithCtx(() => {
        if (isFTUE) {
            bottomDrawerHelperActions.show({
                component: (React.createElement(QPUploadIntroDrawer, { onDismiss: bottomDrawerHelperActions.hide })),
                onClose: () => ftueActions.markComplete()
            });
        }
    }, {}, []);
    return (React.createElement(Column, { style: [styleUtils.flex, style], ...columnProps },
        React.createElement(QPList, { containerStyle: [styleUtils.flex], contentContainerStyle: [styleUtils.spaceBtwHeadNBody, styleUtils.floatingBtnGutter], subjectID: subjectID, onQPPress: onQPPress, groupBy: subject?.type === NodeType.WRITTEN_SUBJECT ? 'year' : 'month', subjectType: subject?.type ?? NodeType.WRITTEN_SUBJECT }),
        React.createElement(Button, { variant: "float", LeftIcon: props => React.createElement(FeatherIcon, { name: "plus", ...props }), onPress: handleAddQP }, "Question Paper")));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QPTab;
