import React from 'react';
import { TypographyFontWeight, TypographyVariant, } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import RowCenter from '../layout/RowCenter';
import Typography from '../typography/Typography';
import withDefaultIconProps from './withDefaultIconProps';
const FormulaIcon = ({ color, size, style, height: _height, ...rest }) => {
    const [{ styles }] = useTheme(stylesGenerator, { size }, [size]);
    return (React.createElement(RowCenter, { style: [styles.container, style], ...rest },
        React.createElement(Typography, { variant: TypographyVariant.SUB_HEADING, lineHeight: size, fontWeight: TypographyFontWeight.REGULAR, color: color }, "\u03A3"),
        React.createElement(Typography, { variant: TypographyVariant.BODY, lineHeight: 16, color: color }, "x")));
};
const stylesGenerator = createThemedStyle(({}, { size }) => ({
    container: {
        width: size,
        height: size,
    },
}));
export default withDefaultIconProps(FormulaIcon);
