import { useCallback, useLayoutEffect, useRef } from 'react';
export default function useStableCallback(cb) {
    const cbRef = useRef(cb);
    useLayoutEffect(() => {
        cbRef.current = cb;
    });
    return useCallback((...args) => {
        return cbRef.current(...args);
    }, []);
}
