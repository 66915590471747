import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Row from 'src/components/10-atoms/layout/Row';
import { ScrollView } from 'react-native';
import ImageThumbnail from 'src/components/10-atoms/form/inputs/FileInput/ImageThumbnail';
import { imageViewHelperActions } from 'src/components/20-helper-views/image-viewer/useImageViewer';
const QPImagesView = ({ data, disablePreview = false, ...rowProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const handleImagePress = (index = 0) => {
        imageViewHelperActions.show({
            initialIndex: index,
            images: data.map(item => ({
                uri: item.uri,
            })),
        });
    };
    return (React.createElement(ScrollView, { horizontal: true },
        React.createElement(Row, { spacing: theme.spacing.standard.xs, ph: theme.spacing.page, pv: theme.spacing.standard.xs, ...rowProps }, data.map((item, index) => (React.createElement(ImageThumbnail, { key: item.uri, src: item.uri, onPress: () => handleImagePress(index) }))))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QPImagesView;
