import React, { useRef } from 'react';
import useDragNDrop from 'src/components/10-atoms/drag-n-drop/useDnD';
import { FileType, } from 'src/components/10-atoms/file-picker/types';
import Row from 'src/components/10-atoms/layout/Row';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useFormStyles from '../../styles';
import AddNewFile from './AddNewFile';
import ImageItem from './ImageItem';
import useHandlers from './useHandlers';
const FileInput = ({ disabled, focused, maxCount = 1, allowedTypes = FileType.IMAGES, value = [], onChange, style, ...rowProps }) => {
    const [{ theme, styles }] = useTheme(stylesGenerator);
    const formStyles = useFormStyles({ focused, disabled });
    const [{ canAddFiles }, handlers] = useHandlers({
        value,
        onChange,
        allowedTypes,
        maxCount,
    });
    const containerRef = useRef(null);
    const [{ dropActive }] = useDragNDrop(containerRef, {
        onFilesDropped(files) {
            handlers.addNewFiles(files);
        },
    });
    return (React.createElement(Row, { ref: containerRef, wrap: true, style: [
            formStyles.inputContainer,
            dropActive && canAddFiles && styles.dropActive,
            style,
        ], pv: theme.spacing.standard.sm, spacing: theme.spacing.standard.md, ...rowProps },
        value.map((item, index) => {
            return (React.createElement(ImageItem, { key: item.id ?? index, data: item, onRemove: handlers.remove, onUploadStart: handlers.uploadStart, onUploadSuccess: handlers.uploadSuccess, onUploadFailure: handlers.uploadFailed }));
        }),
        canAddFiles && React.createElement(AddNewFile, { onPress: handlers.uploadFiles })));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    dropActive: {
        borderStyle: 'dashed',
        borderColor: theme.palette.positive.main,
        borderWidth: 2,
    },
}));
export default FileInput;
