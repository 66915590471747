import { useEffect, useState } from 'react';
import { dragEvent } from '../file-picker/events';
export default function useDragNDrop(containerRef, events = {}) {
    const [dropActive, setDropActive] = useState(false);
    useEffect(() => {
        if (containerRef.current) {
            const container = containerRef.current;
            const handleDragEnter = () => setDropActive(true);
            const handleDragLeave = () => setDropActive(false);
            const handleDragOver = (ev) => ev.preventDefault();
            const handleDrop = (ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                if (dragEvent.containsFiles(ev)) {
                    setDropActive(false);
                    events.onFilesDropped?.(dragEvent.getFiles(ev));
                }
            };
            container.addEventListener('dragenter', handleDragEnter);
            container.addEventListener('dragleave', handleDragLeave);
            container.addEventListener('dragover', handleDragOver);
            container.addEventListener('drop', handleDrop, {
                capture: true,
            });
            return () => {
                container.removeEventListener('dragenter', handleDragEnter);
                container.removeEventListener('dragleave', handleDragLeave);
                container.removeEventListener('dragover', handleDragOver);
                container.removeEventListener('drop', handleDrop, {
                    capture: true,
                });
            };
        }
    }, []);
    return [{ dropActive }];
}
