import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import FitImage from 'src/components/10-atoms/image/FitImage';
import Column from 'src/components/10-atoms/layout/Column';
import Typography from 'src/components/10-atoms/typography/Typography';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import AppAssets from 'src/constants/appAssets';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
const DuplicateContributionDrawer = ({ content, onConfirm, }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Body, { xAxis: "center", spacing: theme.spacing.standard.sm },
            React.createElement(Typography, { variant: TypographyVariant.PAGE_TITLE }, "Duplicate Contribution!!"),
            React.createElement(FitImage, { source: {
                    uri: AppAssets.duplicate,
                }, style: { maxWidth: 400 } }),
            React.createElement(Column, { alignSelf: "stretch" },
                React.createElement(Typography, { align: 'center' }, content.message))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { flex: true, onPress: onConfirm }, "Ok"))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default DuplicateContributionDrawer;
