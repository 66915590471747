import React from 'react';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import RowCenter from 'src/components/10-atoms/layout/RowCenter';
import { PRESSABLE_SIZE } from 'src/components/10-atoms/pressables/constants';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import withPress from 'src/hoc/withPress';
import ThumbnailLayout from './ThumbnailLayout';
import Typography from 'src/components/10-atoms/typography/Typography';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
const AddNewFile = ({ style, ...thumbnailLayoutProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(ThumbnailLayout, { ph: theme.spacing.standard.sm, bcl: theme.palette.primary.main, ...thumbnailLayoutProps, spacing: theme.spacing.standard.xs },
        React.createElement(RowCenter, { ph: theme.spacing.standard.sm, pv: theme.spacing.standard.sm, brd: PRESSABLE_SIZE, bg: theme.palette.background.main },
            React.createElement(FeatherIcon, { name: "image", color: theme.palette.primary.main })),
        React.createElement(Typography, { align: "center", variant: TypographyVariant.CAPTION }, "Upload File")));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default withPress(AddNewFile);
