// import dfFormat from 'date-fns/format';
// import dfParse from 'date-fns/parse';
// import dfStartOfMonth from 'date-fns/startOfMonth';
// import dfEndOfMonth from 'date-fns/endOfMonth';
// import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { formatDistanceToNow, endOfMonth as dfEndOfMonth, startOfMonth as dfStartOfMonth, parse as dfParse, format as dfFormat, } from 'date-fns';
export const getMonth = (() => {
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];
    return (date) => months[date.getMonth()];
})();
export const getDayOfWeek = (() => {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return (date) => daysOfWeek[date.getDay()];
})();
export const DateFormatsPresets = {
    EP_QUESTION_PAPER: 'MONTH_YEAR',
};
export const formatDate = (() => {
    const formats = {
        READER_FRIENDLY: 'EEEE, MMM dd, yyyy',
        MONTH_YEAR: 'MMM yyyy',
        DATE_MONTH_YEAR: 'dd MMM yy',
        TIME: 'hh:mm aa',
        EXPORT: 'yyyyMMdd',
        ISO: 'yyyy-MM-dd',
        LAST_MODIFIED: 'ccc, dd MMM yyyy HH:mm:ss OOOO',
    };
    return (date, requiredFormat, locale) => dfFormat(new Date(date), formats[requiredFormat]);
})();
export const convertDaysToMS = (days) => days * 24 * 60 * 60 * 1000;
export const getReadableDate = (date, mode, locale) => {
    switch (mode) {
        case 'relative':
            return formatDistanceToNow(new Date(date), {
                // locale: {
                //   formatDistance: formatDistanceLocale,
                // },
                addSuffix: true,
            });
        case 'exact':
        default: {
            date = new Date(date);
            const year = date.getFullYear();
            const currentYear = new Date().getFullYear();
            const dateFormat = year === currentYear ? 'dd MMM' : 'dd MMM, yyyy';
            return dfFormat(date, dateFormat);
        }
    }
};
export const parseDate = (dateString, formatString) => {
    return dfParse(dateString, formatString, new Date());
};
export const startOfMonth = (date) => dfStartOfMonth(date);
export const endOfMonth = (date) => dfEndOfMonth(date);
export const stripTimeZone = (date) => {
    if (typeof date !== 'string') {
        return `${dfFormat(date, 'yyyy-MM-dd')}T${dfFormat(date, 'HH:mm:ss.SSS')}`;
    }
    return date.slice(0, 23);
};
export const appendTimeZone = (date) => {
    return `${stripTimeZone(date)}Z`;
};
