import { gql } from '@apollo/client';
import useGQLQuery from 'src/lib/graphql/hooks/useGQLQuery';
const QP_DETAILS_QUERY = gql `
  query EP_QPDetails($qpID: String!) {
    ep_qp(qpID: $qpID) {
      id
      type
      data {
        label
        dateNL
        qpImages {
          uri
        }
      }
      ctime
      author {
        ...AuthorFields
      }
      ancestorsDetails {
        examLevel {
          data {
            title
          }
        }
        writtenSubject {
          data {
            title
          }
        }
        oralsSubject {
          data {
            title
          }
        }
      }
    }
  }
`;
export function useQPDetailsQuery(qpID, options) {
    return useGQLQuery(QP_DETAILS_QUERY, {
        variables: {
            qpID,
        },
        ...options,
    });
}
