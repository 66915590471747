import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import FitImage from 'src/components/10-atoms/image/FitImage';
import Column from 'src/components/10-atoms/layout/Column';
import Typography from 'src/components/10-atoms/typography/Typography';
import ULItem from 'src/components/10-atoms/typography/ULItem';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import AppAssets from 'src/constants/appAssets';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useI18n from 'src/modules/translations/hooks/useI18n';
const AnswerSentForReviewDrawer = ({ onConfirm, }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Body, { xAxis: "center", spacing: theme.spacing.standard.sm },
            React.createElement(Typography, { variant: TypographyVariant.PAGE_TITLE }, translate('Answer submitted successfully 🎉')),
            React.createElement(FitImage, { source: {
                    uri: AppAssets.sentForReviewIllustration,
                } }),
            React.createElement(Column, { alignSelf: "stretch" },
                React.createElement(Typography, null, translate("Here's how it works:")),
                React.createElement(ULItem, null, translate('MarineSamraj team will review your Answer')),
                React.createElement(ULItem, null, translate('Once Approved, the Answer will be updated')),
                React.createElement(ULItem, null, translate("You'll be notified about the updates")))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { flex: true, onPress: onConfirm }, translate('Ok')))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default AnswerSentForReviewDrawer;
