import React from 'react';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import Column from 'src/components/10-atoms/layout/Column';
import Tab from 'src/components/10-atoms/tabs/Tab';
import TabHeader from 'src/components/10-atoms/tabs/TabHeader';
import Tabs from 'src/components/10-atoms/tabs/Tabs';
import Page from 'src/components/templates/page/Page.template';
import PageNavbar from 'src/components/templates/page/PageNavbar.template';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import useI18n from 'src/modules/translations/hooks/useI18n';
import ChatWithUs from '../../components/ChatWithUs';
import { NodeType } from '../../graphql/common/types/node';
import SelectExamLevel from './components/SelectExamLevel';
import SubjectTab from './containers/SubjectTab';
import useData from './useData';
import JoinHiringChat from './components/JoinHiringChat';
const Home = ({ navigation }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const [state, actions] = useData();
    const chatRoomData = state.selectedExamLevel?.examLevel?.config?.EXAM_LEVEL?.chatRoom;
    const handleSearchPress = useCallbackWithCtx(ctx => {
        ctx.navigation.navigate('EP_QuestionSearch', {});
    }, { navigation });
    const handleReviewsPress = useCallbackWithCtx(() => {
        navigation.navigate('RP_Home', {});
    }, []);
    const reviewPendingCount = (state.reviewPendingCountQuery.data?.rp_pendingCount ?? 0) <= 10
        ? state.reviewPendingCountQuery.data?.rp_pendingCount
        : '10+';
    return (React.createElement(Page, null,
        React.createElement(PageNavbar, { title: translate('Exam Preparation'), backType: "none", onBackPress: navigation.goBack, maxVisibleActions: 2, actions: [
                {
                    // eslint-disable-next-line react/no-unstable-nested-components
                    Icon: props => (React.createElement(FeatherIcon, { ...props, name: "search", color: theme.palette.primary.dark })),
                    title: translate('Search'),
                    onPress: handleSearchPress,
                },
                state.isSuperAdmin && {
                    // eslint-disable-next-line react/no-unstable-nested-components
                    Icon: props => (React.createElement(FeatherIcon, { ...props, name: "check-circle", color: theme.palette.primary.dark, notificationCount: reviewPendingCount })),
                    title: translate('Reviews'),
                    onPress: handleReviewsPress,
                },
            ], bbw: 1 }),
        React.createElement(Column, { flex: true },
            React.createElement(JoinHiringChat, null),
            React.createElement(SelectExamLevel, { value: state.selectedExamLevel, onChange: actions.setSelectedExamLevel }),
            state.selectedExamLevel?.examLevel && (React.createElement(Column, { flex: true },
                React.createElement(TabHeader, { tabs: state.tabs.tabs, activeIndex: state.tabs.activeIndex, onChange: actions.tabs.setActiveIndex, ph: theme.spacing.page, bbw: 1 }),
                React.createElement(Tabs, { activeTab: state.tabs.tabs[state.tabs.activeIndex]?.id },
                    React.createElement(Tab, { id: 'WRITTEN' },
                        React.createElement(SubjectTab, { type: NodeType.WRITTEN_SUBJECT, examLevel: state.selectedExamLevel.examLevel })),
                    React.createElement(Tab, { id: 'ORALS' },
                        React.createElement(SubjectTab, { type: NodeType.ORALS_SUBJECT, examLevel: state.selectedExamLevel.examLevel }))))),
            chatRoomData && React.createElement(ChatWithUs, { data: chatRoomData }))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default Home;
