import uniqueId from 'lodash/uniqueId';
import { filePickerHelperViewActions } from 'src/components/10-atoms/file-picker/hooks/useFilePicker';
import { FileType, } from 'src/components/10-atoms/file-picker/types';
import useStableCallback from 'src/hooks/useCallbackUtils/useStableCallback';
export default function useHandlers({ value = [], onChange, allowedTypes = FileType.IMAGES, maxCount = 1, }) {
    const remainingLimit = maxCount - value.length;
    const canAddFiles = remainingLimit > 0;
    const remove = useStableCallback((id) => {
        onChange?.(value.filter(item => item.id !== id));
    });
    const updateUploadRequest = useStableCallback((id, uploadRequest) => {
        if (value && onChange) {
            const index = value.findIndex(item => item.id === id);
            value[index] = {
                ...value[index],
                uploadRequest,
            };
            onChange([...value]);
        }
    });
    const addNewFiles = useStableCallback(async (data) => {
        // Set all the new files in upload-loading state
        const newFilesState = data
            .slice(0, remainingLimit)
            .map(pickedFile => {
            const id = uniqueId();
            return {
                id,
                file: pickedFile,
                uploadRequest: {
                    loading: true,
                    data: null,
                    error: null,
                },
            };
        });
        onChange?.([...value, ...newFilesState]);
    });
    const uploadFiles = useStableCallback(async () => {
        if (!canAddFiles)
            return;
        try {
            const result = await filePickerHelperViewActions.pick({
                allowedTypes,
                allowMultiple: maxCount > 1,
            });
            if (result) {
                addNewFiles(result);
            }
        }
        catch (error) { }
    });
    const uploadStart = useStableCallback((id) => {
        updateUploadRequest(id, {
            loading: true,
            data: null,
            error: null,
        });
    });
    const uploadSuccess = useStableCallback((id, data) => {
        updateUploadRequest(id, {
            loading: false,
            data,
            error: null,
        });
    });
    const uploadFailed = useStableCallback((id, error) => {
        updateUploadRequest(id, {
            loading: false,
            data: null,
            error: error,
        });
    });
    return [
        { remainingLimit, canAddFiles },
        {
            addNewFiles,
            remove,
            uploadFiles,
            uploadStart,
            uploadSuccess,
            uploadFailed,
        },
    ];
}
