import React from 'react';
import ReactNativeModal from 'react-native-modal';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
const ModalBase = ({ isVisible, onDismiss, style, ...rnModalProps }) => {
    const [{ styles, theme, dimensions }] = useTheme(stylesGenerator);
    return (React.createElement(ReactNativeModal, { avoidKeyboard: true, isVisible: isVisible, hideModalContentWhileAnimating: true, onModalHide: () => { }, onBackdropPress: () => {
            onDismiss();
        }, onDismiss: onDismiss, onSwipeComplete: onDismiss, onBackButtonPress: onDismiss, hasBackdrop: true, backdropColor: theme.palette.backdrop.main, backdropOpacity: 1, useNativeDriverForBackdrop: true, style: [styles.container, style], deviceWidth: dimensions.device.width, deviceHeight: dimensions.device.height, ...rnModalProps }));
};
const stylesGenerator = createThemedStyle(() => ({
    container: {
        margin: 0,
    },
}));
export default ModalBase;
