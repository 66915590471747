import { getFieldState } from 'src/components/10-atoms/form/hooks/useFieldState/utils';
import useFormState from 'src/components/10-atoms/form/hooks/useFormState';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
import useStableCallback from 'src/hooks/useCallbackUtils/useStableCallback';
import useContributionResponseHandler from 'src/modules/exam-preparation/containers/ContributionResponseHandler/useContributionResponseHandler';
import { useEPUploadQPMutation } from 'src/modules/exam-preparation/graphql/qp/upload';
import { useSubjectDetailsQuery } from 'src/modules/exam-preparation/graphql/subject/getDetails';
import { stripTimeZone } from 'src/utils/date';
export default function useData(subjectID) {
    const [subjectDetailsQueryState] = useSubjectDetailsQuery(subjectID);
    const [uploadMutationState, uploadMutationActions] = useEPUploadQPMutation();
    const [formState, formActions] = useFormState({
        fields: {
            date: getFieldState(null),
            qpImages: getFieldState([]),
        },
    }, {
        fieldValidations: {
            date: ({ value }) => {
                if (!value) {
                    return 'Please enter a valid examination date';
                }
                return true;
            },
            qpImages: ({ value }) => {
                if (value.length === 0) {
                    return 'Please upload Question Paper images';
                }
                if (value.length > 20) {
                    return 'You can only include upto 20 images';
                }
                return true;
            },
        },
    });
    const handleSubmit = useStableCallback(() => {
        if (formActions.validateForm()) {
            const qpUploading = formState.fields.qpImages.value.some(qpImage => qpImage.uploadRequest.loading);
            if (qpUploading) {
                snackbarHelperActions.show({
                    message: 'Please wait until the images are uploaded',
                    duration: 'SHORT',
                });
                return;
            }
            uploadMutationActions.submit({
                variables: {
                    data: {
                        dateNL: stripTimeZone(formState.fields.date.value),
                        subjectID,
                        qpImages: formState.fields.qpImages.value
                            .filter(qpImage => !!qpImage.uploadRequest.data)
                            .map(qpImage => ({
                            uri: qpImage.uploadRequest.data.publicURL,
                        })),
                    },
                },
            });
        }
    });
    const [, contributionResponseHandler] = useContributionResponseHandler({
        sentForReviewContent: {
            title: 'Question paper uploaded successfully 🎉',
            howItWorksSteps: [
                'MarineSamraj team will review & digitalize the question paper',
                'Once Approved, the question paper will be added',
                "You'll be notified about the updates",
            ],
        },
        thankContributorContent: {
            title: 'QP Added Successfully 🎉',
        },
    });
    const state = {
        form: formState,
        subjectDetailsQuery: subjectDetailsQueryState,
        uploadMutation: uploadMutationState,
    };
    const actions = {
        form: formActions,
        handleSubmit,
        uploadMutation: uploadMutationActions,
        contributionResponseHandler,
    };
    return [state, actions];
}
