import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Animated, Easing, StatusBar, StyleSheet } from 'react-native';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useOnBackPress from 'src/hooks/useOnBackPress';
import styleUtils from 'src/utils/styles';
import Column from '../layout/Column';
const Overlay = ({ visible, onClose, children, style, ...columnProps }) => {
    const [{ styles, theme, dimensions }] = useTheme(stylesGenerator);
    const [hide, setHide] = useState(true);
    const animation = useRef(new Animated.Value(0)).current;
    const [showComponent, setShowComponent] = useState(false);
    useOnBackPress(useCallback(() => {
        if (!visible) {
            return false;
        }
        onClose();
        return true;
    }, [onClose, hide]));
    useEffect(() => {
        if (visible) {
            setHide(false);
            Animated.timing(animation, {
                toValue: 1,
                duration: 250,
                useNativeDriver: false,
                easing: Easing.out(Easing.ease),
            }).start(() => {
                setShowComponent(true);
            });
        }
        else {
            Animated.timing(animation, {
                toValue: 0,
                duration: 250,
                useNativeDriver: false,
                easing: Easing.out(Easing.ease),
            }).start(() => {
                setHide(true);
                setShowComponent(false);
            });
        }
        return () => {
            animation.stopAnimation();
        };
    }, [visible]);
    const bgAnimation = animation.interpolate({
        inputRange: [0, 1],
        outputRange: ['rgba(0,0,0,0)', theme.palette.backdrop.main],
        extrapolate: 'clamp',
    });
    const slideUpAnimation = animation.interpolate({
        inputRange: [0, 1],
        outputRange: [dimensions.device.height, 0],
        extrapolate: 'clamp',
    });
    return !hide ? (React.createElement(Animated.View, { style: [
            styles.bgContainer,
            {
                backgroundColor: bgAnimation,
            },
        ] },
        React.createElement(StatusBar, { backgroundColor: theme.palette.backdrop.main, barStyle: theme.themeID === 'light' ? 'light-content' : 'dark-content' }),
        React.createElement(Animated.View, { style: [
                styles.slideUpContainer,
                styleUtils.containerHeight,
                styleUtils.containerWidth,
                {
                    transform: [
                        {
                            translateY: slideUpAnimation,
                        },
                    ],
                },
            ] },
            React.createElement(Column, { flex: true, style: [styles.contentContainer, style], ...columnProps },
                React.createElement(Column, { flex: true, avoidKeyboard: true, sab: true }, showComponent && children))))) : null;
};
const stylesGenerator = createThemedStyle(({ theme, deviceType, dimensions }) => ({
    bgContainer: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: theme.palette.backdrop.main,
    },
    slideUpContainer: {
        paddingTop: dimensions.safeAreaInsets.top +
            (!deviceType.isPhone ? 36 : theme.spacing.standard.xxs),
    },
    contentContainer: {
        borderTopLeftRadius: theme.borderRadius.standard.lg,
        borderTopRightRadius: theme.borderRadius.standard.lg,
        backgroundColor: theme.palette.background.main,
    },
}));
export default Overlay;
