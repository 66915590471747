import palette from './palette';
import { withOpacity } from './utils';
const black = palette.grey['850'];
const white = palette.grey['100'];
const darkColorScheme = {
    statusBar: {
        content: 'light-content',
    },
    primary: {
        lightest: palette.brand['25'],
        lighter: palette.brand['200'],
        light: palette.brand['400'],
        main: palette.brand['600'],
        dark: palette.brand['700'],
        darker: palette.brand['800'],
        contrast: black,
    },
    notification: {
        main: 'rgba(255,0,0,0.7)',
    },
    background: {
        main: black,
        subtle: palette.grey['825'],
        dark: palette.grey['800'],
        darker: palette.grey['700'],
        darkest: white,
        message: withOpacity(palette.yellow['900'], 0.2),
        highlight: withOpacity(palette.yellow['900'], 0.2),
        unread: palette.brand['25'],
        translate: palette.brand['300'],
        positive: withOpacity(palette.green['500'], 0.35),
        negative: withOpacity(palette.red['300'], 0.35),
        notificationBadge: palette.red['500'],
    },
    backdrop: {
        main: 'rgba(230,230,230,0.65)',
        dark: 'rgba(230,230,230,0.75)',
        darker: 'rgba(230,230,230,0.85)',
    },
    divider: {
        main: palette.grey['700'],
        dark: palette.grey['600'],
    },
    positive: {
        main: `${palette.green['600']}9f`,
        contrast: white,
    },
    negative: {
        main: palette.red['400'],
        contrast: white,
    },
    neutral: {
        main: palette.grey['700'],
        contrast: white,
    },
    typography: {
        primary: palette.grey['300'],
        secondary: palette.grey['500'],
        link: palette.navyBlue,
        placeholder: palette.grey['600'],
        label: palette.grey['600'],
        dark: palette.grey['850'],
    },
    icon: {
        primary: palette.grey['300'],
        secondary: palette.grey['500'],
        dark: palette.grey['50'],
    },
    pressable: {
        active: 'rgba(255,255,255,0.1)',
        disabled: palette.grey['400'],
        disabledText: palette.grey['600'],
    },
    loader: {
        placeholder: 'rgba(255,255,255,0.15)',
    },
    form: {
        inputBorder: palette.grey['400'],
        input: {
            background: {
                focused: palette.grey['850'],
                blurred: palette.grey['800'],
                disabled: palette.grey['800'],
            },
            border: {
                focused: palette.brand['300'],
                blurred: palette.grey['800'],
            },
        },
    },
    border: {
        main: palette.grey['700'],
        dark: palette.grey['400'],
        darker: palette.grey['200'],
        darkest: palette.grey['100'],
    },
    stc: palette.stc,
};
export default darkColorScheme;
